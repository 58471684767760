* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.no-select {
  user-select: none; /* Evita la selección de texto e imágenes */
}
.secure-APP {
  height: 100vh;
  -webkit-tap-highlight-color: transparent;
}
.secure-APP button{
outline: none;
}

:root {
  --bgc: rgb(36, 164, 138);
  --nav-width: 70.5em; /* 600px = 37.5rem */
  --nav-height: 4.7em; /* Aumentado a 5rem */
  --nav-item-size: 4.7em; /* Aumentado a 5rem */
  --indicator-size: 4.7em; /* Aumentado a 5rem */
  --indicator-border: 0.47em; /* Aumentado a 0.5rem */
  --icon-size: 1.8em; /* Tamaño de los iconos */
  color-scheme: light;
}

.nav-link {
  outline: none;
}

.nav-link:focus {
  outline: none;
}

.nav {
  position: relative;
  width: var(--nav-width);
  height: var(--nav-height);
  background: coral;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.625em; /* 10px = 0.625rem */
}

.nav ul {
  display: flex;
  width: 25em; /* Aumentado a 25rem */
}

.nav ul li {
  position: relative;
  list-style-type: none;
  width: var(--nav-item-size);
  height: var(--nav-item-size);
  z-index: 1;
}

.nav ul li a {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  font-weight: 500;
}

.nav ul li a .icon {
  position: relative;
  display: block;
  line-height: 3.5em; /* Aumentado a 5rem */
  font-size: var(--icon-size); /* Utilizando la variable para el tamaño del icono */
  text-align: center;
  color: var(--bgc);
  transition: 0.5s;
}

.nav ul li.active a .icon {
  transform: translateY(-1.55em); /* -35px = -2.1875rem */
}

.nav ul li a .text {
  position: absolute;
  color: aliceblue;
  font-weight: 400;
  font-size: 1em; /* Aumentado el tamaño de la fuente */
  letter-spacing: 0.05em;
  transition: 0.5s;
  opacity: 0;
  transform: translateY(1.5em); /* Ajustado para el nuevo tamaño */
}

.nav ul li.active a .text {
  opacity: 1;
  transform: translateY(0.625em); /* 10px = 0.625rem */
}

.indicator {
  position: absolute;
  top: -50%;
  width: var(--indicator-size); /* Utilizando la variable */
  height: var(--indicator-size); /* Utilizando la variable */
  background: aliceblue;
  border-radius: 50%;
  border: var(--indicator-border) solid var(--bgc);
  transition: 0.5s;
}

.indicator::before {
  content: '';
  top: 50%;
  left: -1.5em; /* Ajustado para el nuevo tamaño */
  position: absolute;
  width: 1.25em; /* 20px = 1.25rem */
  height: 1.25em;
  background: transparent;
  border-top-right-radius: 1.25em;
  box-shadow: 0px -0.625em 0 0 var(--bgc); /* -10px = -0.625rem */
}

.indicator::after {
  content: '';
  top: 50%;
  right: -1.5em; /* Ajustado para el nuevo tamaño */
  position: absolute;
  width: 1.25em; /* 20px = 1.25rem */
  height: 1.25em;
  background: transparent;
  border-top-left-radius: 1.25em;
  box-shadow: -0.0625em -0.625em 0 0 var(--bgc); /* -1px = -0.0625rem, -10px = -0.625rem */
}

.nav ul li:nth-child(1).active ~ .indicator {
  transform: translateX(calc(var(--nav-item-size) * 0));
}

.nav ul li:nth-child(2).active ~ .indicator {
  transform: translateX(calc(var(--nav-item-size) * 1));
}

.nav ul li:nth-child(3).active ~ .indicator {
  transform: translateX(calc(var(--nav-item-size) * 2));
}

.nav ul li:nth-child(4).active ~ .indicator {
  transform: translateX(calc(var(--nav-item-size) * 3));
}

.nav ul li:nth-child(5).active ~ .indicator {
  transform: translateX(calc(var(--nav-item-size) * 4));
}

@keyframes buttonHover {
  0% {
    transform: scale(1);
    background-color: rgb(36, 164, 138);
    border-color: transparent;
  }
  50% {
    transform: scale(1.1);
    background-color: sandybrown;
    border-color: whitesmoke;
  }
  100% {
    transform: scale(1);
    background-color: sandybrown;
    border-color: whitesmoke;
  }
}

.custom-button {
  background-color: rgb(36, 164, 138); /* Verde */
  border: none;
  color: aliceblue;
  padding: 0.9375em 2em; /* 15px 32px = 0.9375rem 2rem */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em; /* 16px = 1rem */
  margin: 0.25em 0.125em; /* 4px 2px = 0.25rem 0.125rem */
  cursor: pointer;
  border-radius: 0.5em; /* 8px = 0.5rem */
  margin-left: 3em; /* Aumenta la separación hacia la derecha */
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

@media (max-width: 480px) {
  .secure-APP {
    max-width: 28em;
  }
  .custom-button {
    padding: 0.75em 1.5em; /* Ajustado para 480px */
    font-size: 0.875em; /* Ajustado para 480px */
    margin: 0.25em 0.25em; /* Ajustado para 480px */
    border-radius: 0.375em; /* Ajustado para 480px */
    margin-left: 2em; /* Ajustado para 480px */
  }

  .swal-wide {
    width: 23em !important;
    text-align: center;
    justify-content: center;
    margin: auto;
  }
}
@media (max-width: 370px) {
  .custom-button {
    padding: 0.625em 1.25em; /* Ajustado para 370px */
    font-size: 0.75em; /* Ajustado para 370px */
    margin: 0.125em 0.125em; /* Ajustado para 370px */
    border-radius: 0.25em; /* Ajustado para 370px */
    margin-left: 1.5em; /* Ajustado para 370px */
  }
}

.custom-button:disabled {
  background-color: sandybrown;
  cursor: not-allowed;
}

.custom-button:hover:not(:disabled) {
  animation-name: buttonHover;
}

.custom-button:hover:not(:disabled) {
  background-color: sandybrown;
  color: aliceblue;
  border: 0.125em solid whitesmoke; /* 2px = 0.125rem */
}

.icon img {
  width: 1.33em; /* Ajusta el tamaño de los iconos */
}

.lenguaje {
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 1em; /* Espacio entre botones */
  margin: 1em; /* Margen alrededor de todo el conjunto de botones */
}

.buttonlenguajeEn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

.buttonlenguajeEn img {
  width: 30px; /* Ajusta el tamaño de las imágenes según sea necesario */
}

/*medias lenguaje*/
@media (max-width: 1170px) {
  .buttonlenguajeEn img {
    width: 29px; /* Ajusta el tamaño de las imágenes según sea necesario */
  }
}

@media (max-width: 768px) {
  .buttonlenguajeEn img {
    width: 23px; /* Ajusta el tamaño de las imágenes según sea necesario */
  }
}

@media (max-width: 1080px) {
  .buttonlenguajeEn img {
    width: 24px; /* Ajusta el tamaño de las imágenes según sea necesario */
  }
}


@media (max-width: 480px) {
  .buttonlenguajeEn img {
    width: 15.3px; /* Ajusta el tamaño de las imágenes según sea necesario */
  }
}
@media (max-width: 370px) {
  .buttonlenguajeEn img {
    width: 13.3px; /* Ajusta el tamaño de las imágenes según sea necesario */
  }
}
/* Slider */
.perraputainfame {
  overflow: hidden;
  width: 100%;
}
.perraputainfame img {
  width: 1.6em;
}
.perraputainfame-track {
  display: flex;
}

.slide-container {
  display: flex;
  animation: marquee 20s linear infinite;
}

.slideputita {
  margin-right: 20px;
  color: gold; /* Puedes ajustar el color a tu preferencia */
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

/* Slide Text */
.slide-textp {
  overflow: hidden;
  height: 30vh; /* Ajusta la altura máxima según tu diseño */
  width: 30vw;
  display: flex;
  justify-content: center;
  text-align: center;
  position: relative;
  color: chocolate;
}

.slide-textp h2 {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding-right: 20px;
  margin-bottom: 0.5em;
}

.slide-list {
  width: 180px; /* Ancho inicial */
  height: 70px; /* Altura inicial */
  list-style: none;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.slide-list li {
  height: 70px;
  line-height: 70px;
  font-size: 1.3em;
  opacity: 0;
  transform: translateY(-100%);
  position: absolute;
  left: 2.5em;
}

.slide-list li:nth-child(1) {
  animation: slideAnimation 3s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
}

@keyframes slideAnimation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Query para ajustes en dispositivos de pantalla pequeña */
@media only screen and (max-width: 1242px) and (max-height: 2688px) {
  .slide-textp {
    width: 100%; /* Ancho completo en dispositivos pequeños */
  }

  .slide-list {
    width: 100%; /* Ancho completo en dispositivos pequeños */
    height: auto; /* Altura automática */
    top: 20px;
    left: 14px;
    transform: translateX(-50%);
  }

  .slide-list li {
    height: auto; /* Altura automática */
    line-height: 1.5;
    transform: translateX(-50%);
  }
}


/* Media queries para dispositivos más pequeños */
@media (max-width: 768px) {
  .custom-button {
    padding: 0.75em 1.5em; /* Reducir padding en dispositivos más pequeños */
    font-size: 0.875em; /* Reducir tamaño de fuente en dispositivos más pequeños */
    margin-left: 1.5em; /* Reducir margen izquierdo en dispositivos más pequeños */
    animation: none; 
  }
}

@media (max-width: 480px) {
  .custom-button {
    padding: 0.2em 0.4em; /* Reducir aún más el padding en dispositivos muy pequeños */
    font-size: 0.55em; /* Reducir tamaño de fuente aún más en dispositivos muy pequeños */
    animation: none; 
  }
}

@media (max-width: 370px) {
  .custom-button {
    padding: 0.3em 0.7em; /* Reducir aún más el padding en dispositivos muy pequeños */
    font-size: 0.55em; /* Reducir tamaño de fuente aún más en dispositivos muy pequeños */
    margin-left: 0.1em; /* Reducir margen izquierdo aún más en dispositivos muy pequeños */
    animation: none; 
  }
}

@media (max-width: 1170px) {
  .custom-button {
    padding: 0.75em 1.5em; /* Reducir padding en dispositivos más pequeños */
    font-size: 0.875em; /* Reducir tamaño de fuente en dispositivos más pequeños */
    margin-left: 1.5em; /* Reducir margen izquierdo en dispositivos más pequeños */
    animation: none; 
  }
}

@media (max-width: 1080px) {
  .custom-button {
    padding: 0.75em 1.5em; /* Reducir padding en dispositivos más pequeños */
    font-size: 0.875em; /* Reducir tamaño de fuente en dispositivos más pequeños */
    margin-left: 1.5em; /* Reducir margen izquierdo en dispositivos más pequeños */
    animation: none; 
  }
}

/* Media queries para dispositivos más pequeños */
@media (max-width: 1170px) {
  :root {
    --nav-width: 45em; /* 480px = 30rem */
    --nav-height: 3.75em; /* 60px = 3.75rem */
    --nav-item-size: 3.75em; /* 60px = 3.75rem */
    --indicator-size: 3.95em; /* 60px = 3.75rem */
    --indicator-border: 0.3125em; /* 5px = 0.3125rem */
  }

  .nav {
    width: var(--nav-width);
    height: var(--nav-height);
    margin: 0 auto; /* Centra el elemento .nav horizontalmente */
  }

  .nav ul {
    width: 18.75em; /* 300px = 18.75rem */
    margin: 0 auto; /* Centra el elemento .nav ul horizontalmente */
  }

  .nav ul li {
    width: var(--nav-item-size);
    height: var(--nav-item-size);
  }

  .nav ul li a .icon {
    line-height: 2.5625em; /* Adjusted to move icons up */
  }
  
  .nav ul li.active a .icon {
    transform: translateY(-0.975em); /* Existing value for active state */
  }
  
  .nav ul li a .text {
    transform: translateY(1.5em); /* Adjusted to raise text */
  }
  
  .nav ul li.active a .text {
    transform: translateY(0.5625em); /* Existing value for active state */
  }
  

  .indicator {
    width: var(--indicator-size);
    height: var(--indicator-size);
    border-width: var(--indicator-border);
  }

  .indicator::before {
    left: -1.25em; /* -20px = -1.25rem */
    width: 1.125em; /* 18px = 1.125rem */
    height: 1.125em; /* 18px = 1.125rem */
  }

  .indicator::after {
    right: -1.25em; /* -20px = -1.25rem */
    width: 1.125em; /* 18px = 1.125rem */
    height: 1.125em; /* 18px = 1.125rem */
  }
}

@media (max-width: 1080px) {
  :root {
    --nav-width: 40.125em; /* 450px = 28.125rem */
    --nav-height: 3.75em; /* 60px = 3.75rem */
    --nav-item-size: 3.75em; /* 60px = 3.75rem */
    --indicator-size: 3.75em; /* 60px = 3.75rem */
    --indicator-border: 0.3125em; /* 5px = 0.3125rem */
  }

  .nav {
    width: var(--nav-width);
    height: var(--nav-height);
    margin: 0 auto; /* Centra el elemento .nav horizontalmente */
  }

  .nav ul {
    width: 18.75em; /* 300px = 18.75rem */
    margin: 0 auto; /* Centra el elemento .nav ul horizontalmente */
  }

  .nav ul li {
    width: var(--nav-item-size);
    height: var(--nav-item-size);
  }

  .nav ul li a .icon {
    line-height: 2.6625em; /* 65px = 4.0625rem */
  }

  .nav ul li.active a .icon {
    transform: translateY(-1.075em); /* -30px = -1.875rem */
  }

  .nav ul li a .text {
    transform: translateY(1em); /* 18px = 1.125rem */
  }

  .nav ul li.active a .text {
    transform: translateY(0.5625em); /* 9px = 0.5625rem */
  }

  .indicator {
    width: var(--indicator-size);
    height: var(--indicator-size);
    border-width: var(--indicator-border);
  }

  .indicator::before {
    left: -1.25em; /* -20px = -1.25rem */
    width: 1.125em; /* 18px = 1.125rem */
    height: 1.125em; /* 18px = 1.125rem */
  }

  .indicator::after {
    right: -1.25em; /* -20px = -1.25rem */
    width: 1.125em; /* 18px = 1.125rem */
    height: 1.125em; /* 18px = 1.125rem */
  }
}

@media (max-width: 768px) {
  :root {
    --nav-width: 31.25em; /* 500px = 31.25rem */
    --nav-height: 3.75em; /* 60px = 3.75rem */
    --nav-item-size: 3.75em; /* 60px = 3.75rem */
    --indicator-size: 3.75em; /* 60px = 3.75rem */
    --indicator-border: 0.3125em; /* 5px = 0.3125rem */
  }

  .nav {
    width: var(--nav-width);
    height: var(--nav-height);
    margin: 0 auto; /* Centra el elemento .nav horizontalmente */
  }

  .nav ul {
    width: 18.75em; /* 300px = 18.75rem */
    margin: 0 auto; /* Centra el elemento .nav ul horizontalmente */
  }

  .nav ul li {
    width: var(--nav-item-size);
    height: var(--nav-item-size);
  }

  .nav ul li a .icon {
    line-height: 2.625em; /* 65px = 4.0625rem */
  }

  .nav ul li.active a .icon {
    transform: translateY(-1.075em); /* -30px = -1.875rem */
  }

  .nav ul li a .text {
    transform: translateY(1.125em); /* 18px = 1.125rem */
  }

  .nav ul li.active a .text {
    transform: translateY(0.5625em); /* 9px = 0.5625rem */
  }

  .indicator {
    width: var(--indicator-size);
    height: var(--indicator-size);
    border-width: var(--indicator-border);
  }

  .indicator::before {
    left: -1.25em; /* -20px = -1.25rem */
    width: 1.125em; /* 18px = 1.125rem */
    height: 1.125em; /* 18px = 1.125rem */
  }

  .indicator::after {
    right: -1.25em; /* -20px = -1.25rem */
    width: 1.125em; /* 18px = 1.125rem */
    height: 1.125em; /* 18px = 1.125rem */
  }
}

@media (max-width: 480px) {
  :root {
    --nav-width: 21.8em; /* Ajustado a 26em */
    --nav-height: 3.125em; /* 50px = 3.125rem */
    --nav-item-size: 2.5em; /* Hacer más pequeños los iconos a 2.5em */
    --indicator-size: 2.5em; /* Ajustar el tamaño del indicador a 2.5em */
    --indicator-border: 0.25em; /* 4px = 0.25rem */
  }

  .nav {
    width: var(--nav-width);
    height: var(--nav-height);
    margin: 0, auto; /* Centra el elemento .nav horizontalmente */
    display: flex;
    align-items: center; /* Centra verticalmente los elementos dentro de .nav */
    justify-content: center; /* Centra horizontalmente los elementos dentro de .nav */
  }

  .nav ul {
    width: 12.375em; /* 230px = 14.375rem */
    margin: 0 auto; /* Centra el elemento .nav ul horizontalmente */
  }

  .nav ul li {
    width: var(--nav-item-size);
    height: var(--nav-item-size);
  }

  .nav ul li a .icon {
    line-height: 2em; /* Ajustar la altura del ícono */
    font-size: 0.99em; /* Tamaño del ícono */
  }

  .nav ul li.active a .icon {
    transform: translateY(-1.42em); /* Ajustar posición del ícono activo */
  }

  .nav ul li a .text {
    transform: translateY(0.5em); /* Ajustar posición del texto */
    font-size: 0.75em; /* Tamaño del texto */
  }

  .nav ul li.active a .text {
    transform: translateY(0.25em); /* Ajustar posición del texto activo */
  }

  .indicator {
    width: var(--indicator-size);
    height: var(--indicator-size);
    border-width: var(--indicator-border);
  }

  .indicator::before {
    left: -0.5em; /* Ajustar posición del indicador ::before */
    width: 0.5em; /* Tamaño del indicador ::before */
    height: 0.5em; /* Tamaño del indicador ::before */
  }

  .indicator::after {
    right: -0.5em; /* Ajustar posición del indicador ::after */
    width: 0.5em; /* Tamaño del indicador ::after */
    height: 0.5em; /* Tamaño del indicador ::after */
  }
}

@media (max-width: 340px) {
  :root {
    --nav-width: 15.775em; /* Ajustado a 26em */
    --nav-height: 3.125em; /* 50px = 3.125rem */
    --nav-item-size: 2.5em; /* Hacer más pequeños los iconos a 2.5em */
    --indicator-size: 2.5em; /* Ajustar el tamaño del indicador a 2.5em */
    --indicator-border: 0.25em; /* 4px = 0.25rem */
  }

  .nav {
    width: var(--nav-width);
    height: var(--nav-height);
    margin: 0, auto; /* Centra el elemento .nav horizontalmente */
    display: flex;
    align-items: center; /* Centra verticalmente los elementos dentro de .nav */
    justify-content: center; /* Centra horizontalmente los elementos dentro de .nav */
  }

  .nav ul {
    width: 12.375em; /* 230px = 14.375rem */
    margin: 0 auto; /* Centra el elemento .nav ul horizontalmente */
  }

  .nav ul li {
    width: var(--nav-item-size);
    height: var(--nav-item-size);
  }

  .nav ul li a .icon {
    line-height: 2em; /* Ajustar la altura del ícono */
    font-size: 0.99em; /* Tamaño del ícono */
  }

  .nav ul li.active a .icon {
    transform: translateY(-1.42em); /* Ajustar posición del ícono activo */
  }

  .nav ul li a .text {
    transform: translateY(0.5em); /* Ajustar posición del texto */
    font-size: 0.75em; /* Tamaño del texto */
  }

  .nav ul li.active a .text {
    transform: translateY(0.25em); /* Ajustar posición del texto activo */
  }

  .indicator {
    width: var(--indicator-size);
    height: var(--indicator-size);
    border-width: var(--indicator-border);
  }

  .indicator::before {
    left: -0.5em; /* Ajustar posición del indicador ::before */
    width: 0.5em; /* Tamaño del indicador ::before */
    height: 0.5em; /* Tamaño del indicador ::before */
  }

  .indicator::after {
    right: -0.5em; /* Ajustar posición del indicador ::after */
    width: 0.5em; /* Tamaño del indicador ::after */
    height: 0.5em; /* Tamaño del indicador ::after */
  }
}

@media (max-width: 370px) {
  :root {
    --nav-width: 21.775em; /* Ajustado a 26em */
    --nav-height: 3.125em; /* 50px = 3.125rem */
    --nav-item-size: 2.5em; /* Hacer más pequeños los iconos a 2.5em */
    --indicator-size: 2.5em; /* Ajustar el tamaño del indicador a 2.5em */
    --indicator-border: 0.25em; /* 4px = 0.25rem */
  }

  .nav {
    width: var(--nav-width);
    height: var(--nav-height);
    margin: 0, auto; /* Centra el elemento .nav horizontalmente */
    display: flex;
    align-items: center; /* Centra verticalmente los elementos dentro de .nav */
    justify-content: center; /* Centra horizontalmente los elementos dentro de .nav */
  }

  .nav ul {
    width: 14.375em; /* 230px = 14.375rem */
    margin: 0 auto; /* Centra el elemento .nav ul horizontalmente */
  }

  .nav ul li {
    width: var(--nav-item-size);
    height: var(--nav-item-size);
  }

  .nav ul li a .icon {
    line-height: 2em; /* Ajustar la altura del ícono */
    font-size: 1.1em; /* Tamaño del ícono */
  }

  .nav ul li.active a .icon {
    transform: translateY(-1.22em); /* Ajustar posición del ícono activo */
  }

  .nav ul li a .text {
    transform: translateY(0.5em); /* Ajustar posición del texto */
    font-size: 0.75em; /* Tamaño del texto */
  }

  .nav ul li.active a .text {
    transform: translateY(0.25em); /* Ajustar posición del texto activo */
  }

  .indicator {
    width: var(--indicator-size);
    height: var(--indicator-size);
    border-width: var(--indicator-border);
  }

  .indicator::before {
    left: -0.5em; /* Ajustar posición del indicador ::before */
    width: 0.5em; /* Tamaño del indicador ::before */
    height: 0.5em; /* Tamaño del indicador ::before */
  }

  .indicator::after {
    right: -0.5em; /* Ajustar posición del indicador ::after */
    width: 0.5em; /* Tamaño del indicador ::after */
    height: 0.5em; /* Tamaño del indicador ::after */
  }
}

@media (max-width: 480px) {
  .tu-clase {
      outline: none;
  }
  .tu-clase:focus {
      outline: none;
      box-shadow: none;
  }
}
