.bank-container {
  width: 60em;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 0.625em; /* Aplica el radio a los 4 bordes */
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center; /* Centra todo el contenido dentro del contenedor */
  font-family: 'Poppins', sans-serif; /* Font family */
  }
  
  .bank-container h1.bank {
    font-size: 24px;
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  .letrasP {
    font-size: 18px;
    color: #34495e;
    line-height: 1.6;
    margin: 10px 0;
    width: 100%; /* Asegura que el elemento se alinee al centro */
  }
  
  .bank-container p.letrasP {
    display: block;
    margin: 0 auto; /* Centra el párrafo */
    max-width: 30em; /* Limita el ancho máximo para que no ocupe todo el contenedor */
  }
  
  .bank-container p.letrasP::before {
    content: attr(data-label);
    font-weight: bold;
    display: block; /* Asegura que el label esté en su propia línea */
    margin-bottom: 0.5em; /* Espacio entre el label y el valor */
  }
  

  /* Media queries para dispositivos más pequeños */
@media (max-width: 1170px) {
    .bank-container { width: 45em; }
}

@media (max-width: 768px) {
    .bank-container { width: 31em; }
}

@media (max-width: 1080px) {
    .bank-container { width: 31em; }
}


@media (max-width: 480px) {
  .bank-container {
    width: 20em;
    margin: 1.25em auto; /* Ajustar margen a 20px = 1.25em */
    padding: 1.25em; /* Ajustar padding a 20px = 1.25em */
    border-radius: 0.5em; /* Ajustar border-radius a 8px = 0.5em */
}

.bank-container h1.bank {
    font-size: 1.5em; /* Ajustar font-size a 24px = 1.5em */
    margin-bottom: 1em; /* Ajustar margin-bottom a 20px = 1em */
}

.letrasP {
    font-size: 1em; /* Ajustar font-size a 16px = 1em */
    margin: 0.625em 0; /* Ajustar margen a 10px = 0.625em */
    line-height: 1.5; /* Ajustar line-height */
}

.bank-container p.letrasP {
    max-width: 18em; /* Limitar el ancho máximo del párrafo */
}
}
