.Stake-container {
  width: 60em;
  margin: 10px auto;
  padding: 20px;
  border-radius: 10px;
  text-align: center; /* Centra todo el contenido dentro del contenedor */
  overflow: hidden;
}

.StakE {
  width: 60em;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 0.625em; 
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center; /* Centra todo el contenido dentro del contenedor */
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra los elementos hijos horizontalmente */
}

.letraszr {
  font-size: 18px;
  color: #34495e;
  line-height: 1.6;
  margin: 10px 0;
  width: 100%; /* Asegura que el elemento se alinee al centro */
  max-width: 30em; /* Limita el ancho máximo para que no ocupe todo el contenedor */
  text-align: center; /* Centra el texto dentro del elemento */
}

.StakE p.letraszr {
  display: block;
  margin: 0 auto; /* Centra el párrafo */
}

.StakE p.letraszr::before {
  content: attr(data-label);
  font-weight: bold;
  display: block; /* Asegura que el label esté en su propia línea */
  margin-bottom: 0.5em; /* Espacio entre el label y el valor */
  text-align: center; /* Centra el texto del label */
}

/* Media queries para dispositivos más pequeños */
@media (max-width: 1170px) {
  .Stake-container { width: 45em; }
  .StakE { width: 45em; }
}

@media (max-width: 768px) {
  .Stake-container { width: 31em; }
  .StakE { width: 31em; }
}

@media (max-width: 1080px) {
  .Stake-container { width: 31em; }
  .StakE { width: 31em; }
}

@media (max-width: 480px) {
  .Stake-container { width: 20em; }
  .StakE { width: 20em; }
  .letraszr {
      font-size: 1em; /* Ajustar font-size a 16px = 1em */
      margin: 0.625em 0; /* Ajustar margen a 10px = 0.625em */
      line-height: 1.5; /* Ajustar line-height */
  }

  .StakE p.letraszr {
      max-width: 18em; /* Limitar el ancho máximo del párrafo */
  }

  .huevosre {
      width: 15em; /* Ajustar el ancho para que se ajuste dentro del contenedor */
      height: auto; /* Ajustar la altura automáticamente */
      max-width: 100%; /* Asegurar que no exceda el 100% del contenedor */
  }
}

.huevosre {
  height: 400px;
  width: 330px;
  background-color: #ffffff;
  margin: 0.5em auto; /* Centra el div */
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
  box-shadow: 25px 0 rgba(0,0,0,0.1);
  display: flex; /* Asegura que el contenido esté alineado verticalmente */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif; /* Font family */
}

.ButtomStake {
  margin: 10px 0; /* Espacio entre los botones */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: coral;
  color: aliceblue;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ButtomStake:hover {
  background-color: sandybrown;
}

.InputStake {
  margin: 10px 0; /* Espacio alrededor del input */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  text-align: center;
  justify-content: center;
}

.InputStake::-webkit-outer-spin-button,
.InputStake::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.InputStake[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
