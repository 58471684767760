.cobteiner-bar {
    width: 60em;
    margin: 10px auto;
    padding: 20px;
    border-radius: 10px;
    text-align: center; /* Centra todo el contenido dentro del contenedor */
    overflow: hidden;
    font-family: 'Poppins', sans-serif; /* Font family */
  }

    /* Media queries para dispositivos más pequeños */
    @media (max-width: 1170px) {
        .cobteiner-bar { width: 45em; }
      }
      
      @media (max-width: 768px) {
        .cobteiner-bar { width: 31em; }
      }
      
      @media (max-width: 1080px) {
        .cobteiner-bar { width: 31em; }
      }
      
      @media (max-width: 480px) {
        .cobteiner-bar { width: 20em; }
        .Conteiner-barP { width: 20em; }
        .progress-label-letras {
          font-size: 1em; /* Ajustar font-size a 16px = 1em */
      margin: 0.625em 0; /* Ajustar margen a 10px = 0.625em */
      line-height: 1.5; /* Ajustar line-height */
        }

        .Conteiner-barP p.progress-label-letras {
          max-width: 18em;
        }
      }

.progress-container {
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    height: 30px;
    margin: 20px 0;
    text-align: center;
    justify-content: center;
}

.progress-bar {
    height: 100%;
    background-color: #4caf50;
    width: 0%;
    transition: width 0.5s ease;
}

.progress-label {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    color: #333;
}
