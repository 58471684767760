.box-about{
    width: 60em;
    margin: 10px auto;
    padding: 20px;
    border-radius: 10px;
    text-align: center; /* Centra todo el contenido dentro del contenedor */
    overflow: hidden;
    font-family: 'Poppins', sans-serif; /* Font family */
}
 /* Media queries para dispositivos más pequeños */
 @media (max-width: 1170px) {
    .box-about { width: 45em; }
  }
  
  @media (max-width: 768px) {
    .box-about { width: 31em; }
  }
  
  @media (max-width: 1080px) {
    .box-about { width: 31em; }
  }
  
  @media (max-width: 480px) {
    .box-about { width: 25em; }
  }
.accordion-item {
    margin: 0.7em auto;
}

.accordion-question {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.accordion-question h3 {
    margin: 0;
    text-align: center;
    flex: 1; /* Esto hace que el h3 ocupe el máximo espacio posible */
}

.chevron-icon {
    margin-left: 10px; /* Espacio entre el icono y el texto */
    display: flex;
    align-items: center; /* Alinea verticalmente el icono */
}

.accordion-answer {
    padding: 10px;
}

.answer-text {
    margin: 0 auto;
    text-align: center;
    width: 60em; /* Ancho por defecto para pantallas grandes */
    max-width: 100%; /* Asegura que el ancho máximo sea el 100% del contenedor padre */
}

/* Media queries para ajustar el ancho del párrafo en diferentes tamaños de pantalla */
@media (max-width: 1170px) {
    .answer-text {
        width: 45em; /* Ancho para pantallas de hasta 1170px */
    }
}

@media (max-width: 1080px) {
    .answer-text {
        width: 31em; /* Ancho para pantallas de hasta 1080px */
    }
}

@media (max-width: 768px) {
    .answer-text {
        width: 31em; /* Ancho para pantallas de hasta 768px */
    }
}

@media (max-width: 480px) {
    .answer-text {
        width: 25em; /* Ancho para pantallas de hasta 480px */
    }
}
