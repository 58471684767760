.timeline-item {
    position: relative;
    margin-bottom: 40px;
    width: 70%; /* Limitar el ancho del contenedor a un máximo del 70% */
    margin-left: auto;
    margin-right: auto; /* Centrar el contenedor horizontalmente */
  }
  
  .year-container {
    display: flex;
    align-items: center;
    justify-content: center; /* Centrar el año en el medio del contenedor */
    cursor: pointer;
    background-color: coral;
    color: white;
    padding: 10px 20px;
    border-radius: 25px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .year-container:hover {
    background-color: sandybrown;
    transform: scale(1.02);
  }
  
  .year {
    font-size: 18px;
    font-weight: bold;
  }
  
  .arrow {
    margin-left: 10px;
    transition: transform 0.3s ease;
  }
  
  .arrow.expanded {
    transform: rotate(180deg);
  }
  
  .bubble {
    margin-top: 20px;
    background-color: white;
    border: 1px solid #cccccc;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    text-align: left;
    position: relative;
    margin-left: auto;
    margin-right: auto; /* Centrar la burbuja horizontalmente */
    text-align: center;
    align-items: center;
    justify-content: center; 
  }
  
  .bubble:after {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%; /* Centrar la flecha en el medio de la burbuja */
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
  
  .bubble-content {
    margin-top: 10px;
  }
  
  .bubble p {
    margin: 0;
    padding: 0;
    color: #333;
    font-size: 16px;
    line-height: 1.5;
  }
  