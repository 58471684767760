.Miner-container {
    width: 60em;
    margin: 10px auto;
    padding: 20px;
    border-radius: 10px;
    text-align: center; /* Centra todo el contenido dentro del contenedor */
    overflow: hidden;
    font-family: 'Poppins', sans-serif; /* Font family */
  }
  
  .Miner {
    width: 60em;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 0.625em;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center; /* Centra todo el contenido dentro del contenedor */
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra los elementos hijos horizontalmente */
    position: relative; /* Añadido para el z-index */
    z-index: 2; /* Asegura que los elementos estén delante */
  }
  
  .letrasMi {
    font-size: 18px;
    color: #34495e;
    line-height: 1.6;
    margin: 10px 0;
    width: 100%; /* Asegura que el elemento se alinee al centro */
    max-width: 30em; /* Limita el ancho máximo para que no ocupe todo el contenedor */
    text-align: center; /* Centra el texto dentro del elemento */
  }
  
  .Miner p.letrasMi {
    display: block;
    margin: 0 auto; /* Centra el párrafo */
  }
  
  .Miner p.letrasMi::before {
    content: attr(data-label);
    font-weight: bold;
    display: block; /* Asegura que el label esté en su propia línea */
    margin-bottom: 0.5em; /* Espacio entre el label y el valor */
    text-align: center; /* Centra el texto del label */
  }

  .referidos {
    margin: 0.2em;
    display: flex;
    align-items: center;
    justify-content: center; /* Centra horizontalmente los elementos */
    text-align: center; /* Alinea el texto centrado dentro del contenedor */
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  
  
  /* Media queries para dispositivos más pequeños */
  @media (max-width: 1170px) {
    .Miner-container { width: 45em; }
    .Miner { width: 45em; }
  }
  
  @media (max-width: 768px) {
    .Miner-container { width: 31em; }
    .Miner { width: 31em; }
  }
  
  @media (max-width: 1080px) {
    .Miner-container { width: 31em; }
    .Miner { width: 31em; }
  }
  
  @media (max-width: 480px) {
    .Miner-container { width: 20em; }
    .Miner { width: 20em; }
    .letrasMi {
      font-size: 1em; /* Ajustar font-size a 16px = 1em */
      margin: 0.625em 0; /* Ajustar margen a 10px = 0.625em */
      line-height: 1.5; /* Ajustar line-height */
  }
  .Miner p.letrasMi {
    max-width: 18em; /* Limitar el ancho máximo del párrafo */
}
.referidos { width: 20em; }
  }
  
  .Tortilla2 {
    height: 400px;
    width: 400px;
    background-color: maroon; /* Color de Tortilla2 */
    margin: 0.5em auto; /* Centra el div */
    border-radius: 40% 50% 60% 40% / 50% 40% 70% 60%;
    box-shadow: 10px 10px 15px rgba(22, 11, 0, 0.2);
    display: flex; /* Asegura que el contenido esté alineado verticalmente */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative; /* Necesario para las manchas */
    z-index: 1; /* Asegura que la Tortilla2 esté detrás */
  }

  @media (max-width: 480px) {
    .Tortilla2 { height: 270px;
      width: 270px; }
  }
  
  .Tortilla2 .mancha2 {
    position: absolute;
    border-radius: 50%;
    background-color: rgba(245, 81, 31, 0.2); /* Color de manchas de Tortilla2 */
    opacity: 0.7;
    z-index: -1; /* Asegura que las manchas estén detrás */
  }
  
  .Tortilla2 .mancha12 {
    width: 60px;
    height: 60px;
    top: 20%;
    left: 30%;
  }
  
  .Tortilla2 .mancha22 {
    width: 80px;
    height: 80px;
    bottom: 25%;
    right: 25%;
  }
  
  .Tortilla2 .mancha32 {
    width: 50px;
    height: 50px;
    top: 30%;
    left: 70%;
  }
  
  .Tortilla2 .mancha42 {
    width: 70px;
    height: 70px;
    bottom: 10%;
    right: 50%;
  }
  
  .Tortilla2 .mancha52 {
    width: 40px;
    height: 40px;
    top: 50%;
    right: 70%;
  }
  .ButtomMiner {
    margin: 10px 0; /* Espacio entre los botones */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: rgb(247, 69, 4);
    color: aliceblue;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 2; /* Asegura que los botones estén delante */
  }
  
  .ButtomMiner:hover {
    background-color: rgb(244, 150, 96);
  }
  .ButtomMiner2 {
    margin: 10px 0; /* Espacio entre los botones */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: coral;
    color: aliceblue;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 2; /* Asegura que los botones estén delante */
  }
  
  .ButtomMiner2:hover {
    background-color: sandybrown;
  }
  
  .InputMiner {
    margin: 10px 0; /* Espacio alrededor del input */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 200px;
    z-index: 2; /* Asegura que los inputs estén delante */
    text-align: center;
    justify-content: center;
  }
  
  .InputMiner::-webkit-outer-spin-button,
  .InputMiner::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .InputMiner[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  
  .comerandreinvest {
    background-color: rgba(0, 0, 0, 0.5); /* Fondo transparente con opacidad */
    padding: 10px; /* Ajusta el padding según necesites */
    margin: 0.3em auto; /* Centra horizontalmente */
    box-sizing: border-box; /* Incluye padding en el ancho total */
    display: flex; /* Activa el flexbox */
    justify-content: space-around; /* Distribuye los elementos horizontalmente con espacio alrededor */
    flex-wrap: wrap; /* Permite que los elementos se envuelvan a una nueva línea si no caben en el ancho disponible */
    border-radius: 10px;
    overflow: hidden;
  }
  
  @media screen and (max-width: 480px) {
    .comerandreinvest {
      width: 20em; /* Ocupa el ancho completo */
      background-color: transparent;
    }
  }
  
  @media screen and (min-width: 481px) and (max-width: 600px) {
    .comerandreinvest {
      width: 20em; /* Ocupa el ancho completo para pantallas entre 481px y 600px */
      background-color: transparent;
    }
  }
  
  @media screen and (min-width: 601px) and (max-width: 1120px) {
    .comerandreinvest {
      width: 31em; /* Ancho del 80% para pantallas entre 601px y 1120px */
    }
  }
  
  @media screen and (min-width: 1121px) and (max-width: 1080px) {
    .comerandreinvest {
      width: 36em; /* Ancho del 70% para pantallas entre 1121px y 1080px */
    }
  }
  
  @media screen and (min-width: 1081px) {
    .comerandreinvest {
      width: 31em; /* Ancho del 60% para pantallas mayores a 1080px */
    }
  }
  