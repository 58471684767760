/* Footer.css */
.footer-container {
    user-select: none;
    font-weight: bold;
    background-color: transparent;
    padding: 20px;
    text-align: center;
    color: aliceblue;
    margin: 1em auto; /* Cambiado a auto para centrar el contenedor */
    display: flex;
    flex-direction: column; /* Asegura que los hijos estén en una columna */
    align-items: center; /* Centra los elementos hijos horizontalmente */
    width: 100%; /* Asegura que ocupe todo el ancho del contenedor */
    box-sizing: border-box; /* Incluye el padding y border en el ancho */
}

.footer-socials {
    margin-bottom: 10px;
}

.footer-link {
    color: aliceblue;
    text-decoration: none;
    margin: 0 10px;
    display: inline-block;
}

.footer-link:hover {
    text-decoration: underline;
}

.footer-rights {
    border-top: 1px solid coral;
    padding-top: 10px;
    margin-top: 10px;
    width: 100%; /* Asegura que la línea de borde ocupe todo el ancho */
    text-align: center; /* Centra el texto */
}

/* Media queries para dispositivos más pequeños */
@media (max-width: 1170px) {
    .footer-container { width: 45em; }
}

@media (max-width: 768px) {
    .footer-container { width: 31em; }
}

@media (max-width: 1080px) {
    .footer-container { width: 31em; }
}


@media (max-width: 480px) {
    .footer-container { width: 20em; }
}
