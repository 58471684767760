.Buy-container {
    width: 60em;
    margin: 10px auto;
    padding: 20px;
    border-radius: 10px;
    text-align: center; /* Centra todo el contenido dentro del contenedor */
    overflow: hidden;
    font-family: 'Poppins', sans-serif; /* Font family */
  }
  
  .BuY {
    width: 60em;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 0.625em;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center; /* Centra todo el contenido dentro del contenedor */
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra los elementos hijos horizontalmente */
    position: relative; /* Añadido para el z-index */
    z-index: 2; /* Asegura que los elementos estén delante */
  }
  
  .letrasB {
    font-size: 18px;
    color: #34495e;
    line-height: 1.6;
    margin: 10px 0;
    width: 100%; /* Asegura que el elemento se alinee al centro */
    max-width: 30em; /* Limita el ancho máximo para que no ocupe todo el contenedor */
    text-align: center; /* Centra el texto dentro del elemento */
  }
  
  .BuY p.letrasB {
    display: block;
    margin: 0 auto; /* Centra el párrafo */
  }
  
  .BuY p.letrasB::before {
    content: attr(data-label);
    font-weight: bold;
    display: block; /* Asegura que el label esté en su propia línea */
    margin-bottom: 0.5em; /* Espacio entre el label y el valor */
    text-align: center; /* Centra el texto del label */
  }

  .referidos {
    margin: 0.2em;
    display: flex;
    align-items: center;
    justify-content: center; /* Centra horizontalmente los elementos */
    text-align: center; /* Alinea el texto centrado dentro del contenedor */
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  
  
  /* Media queries para dispositivos más pequeños */
  @media (max-width: 1170px) {
    .Buy-container { width: 45em; }
    .BuY { width: 45em; }
  }
  
  @media (max-width: 768px) {
    .Buy-container { width: 31em; }
    .BuY { width: 31em; }
  }
  
  @media (max-width: 1080px) {
    .Buy-container { width: 31em; }
    .BuY { width: 31em; }
  }
  
  @media (max-width: 480px) {
    .Buy-container { width: 20em; }
    .BuY { width: 20em; }
    .letrasB {
      font-size: 1em; /* Ajustar font-size a 16px = 1em */
      margin: 0.625em 0; /* Ajustar margen a 10px = 0.625em */
      line-height: 1.5; /* Ajustar line-height */
  }
  .Buy p.letrasB {
    max-width: 18em; /* Limitar el ancho máximo del párrafo */
}
.referidos { width: 20em; }
  }
  
  .Tortilla {
    height: 400px;
    width: 400px;
    background-color: #f5e1a4; /* Color de Tortilla */
    margin: 0.5em auto; /* Centra el div */
    border-radius: 40% 50% 60% 40% / 50% 40% 70% 60%;
    box-shadow: 10px 10px 15px rgba(0,0,0,0.2);
    display: flex; /* Asegura que el contenido esté alineado verticalmente */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative; /* Necesario para las manchas */
    z-index: 1; /* Asegura que la tortilla esté detrás */
  }

  @media (max-width: 480px) {
    .Tortilla { height: 270px;
      width: 270px; }
  }
  
  .Tortilla .mancha {
    position: absolute;
    border-radius: 50%;
    background-color: #e0c084; /* Color de manchas de Tortilla */
    opacity: 0.7;
    z-index: -1; /* Asegura que las manchas estén detrás */
  }
  
  .Tortilla .mancha1 {
    width: 60px;
    height: 60px;
    top: 20%;
    left: 30%;
  }
  
  .Tortilla .mancha2 {
    width: 80px;
    height: 80px;
    bottom: 25%;
    right: 25%;
  }
  
  .Tortilla .mancha3 {
    width: 50px;
    height: 50px;
    top: 30%;
    left: 70%;
  }
  
  .Tortilla .mancha4 {
    width: 70px;
    height: 70px;
    bottom: 10%;
    right: 50%;
  }
  
  .Tortilla .mancha5 {
    width: 40px;
    height: 40px;
    top: 50%;
    right: 70%;
  }
  
  .ButtomBuY {
    margin: 10px 0; /* Espacio entre los botones */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: coral;
    color: aliceblue;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 2; /* Asegura que los botones estén delante */
  }
  
  .ButtomBuY:hover {
    background-color: sandybrown;
  }
  
  .InputBuY {
    margin: 10px 0; /* Espacio alrededor del input */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 200px;
    z-index: 2; /* Asegura que los inputs estén delante */
    text-align: center;
    justify-content: center;
  }
  
  .InputBuY::-webkit-outer-spin-button,
  .InputBuY::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .InputBuY[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  