.carousel-content {
    display: flex; /* Cambiado de 'none' a 'flex' para que sea visible */
    flex-direction: column; /* Para alinear los elementos verticalmente */
    align-items: center; /* Centra los elementos horizontalmente */
    background: #fff;
    padding: 1.25em; /* Cambiado de '20px' a '1.25em' */
    width: calc(100% - 2.5em); /* Cambiado de '40px' a '2.5em' */
    max-width: 18.75em; /* Cambiado de '300px' a '18.75em' */
    line-height: 1.5; /* Cambiado de '150%' a '1.5' */
    border-radius: 0.625em; /* Cambiado de '10px' a '0.625em' */
    position: relative; /* Cambiado de 'fixed' a 'relative' */
    z-index: 100;
    padding-top: 3.75em; /* Cambiado de '60px' a '3.75em' */
    box-shadow: 0 0.125em 1.25em 0.625em rgba(222, 222, 222, 0.25); /* Cambiado de 'px' a 'em' */
    text-align: center;
    margin: auto;
}

.carousel-content img {
    max-width: 6.25em; /* Cambiado de '100px' a '6.25em' */
    position: absolute;
    top: -3.125em; /* Cambiado de '-50px' a '-3.125em' */
    left: calc(50% - 3.125em); /* Cambiado de '50px' a '3.125em' */
}

.carousel-content h3,
.carousel-content p {
    margin-bottom: 0.9375em; /* Cambiado de '15px' a '0.9375em' */
    color: #333;
}
