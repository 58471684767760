/* Disclaimer.css */
.disclaimer-container {
    user-select: none;
    background-color: #fefefe; /* Light background color */
    border: 0.063em solid #ccc; /* Light border */
    padding: 1.25em; /* Space inside the container */
    margin: 1.25em auto; /* Centered container with margin */
    max-width: 50em; /* Maximum width for larger screens */
    text-align: left; /* Text alignment */
    border-radius: 0.625em; /* Rounded corners */
    box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    font-family: 'Poppins', sans-serif; /* Font family */
    color: #333; /* Text color */
    position: relative; /* Position relative for the close button */
}

.disclaimer-close {
    background: none;
    border: none;
    font-size: 1.25em; /* Close button font size */
    position: absolute;
    top: 0.225em;
    right: 0.225em;
    cursor: pointer;
    color: #666; /* Close button color */
}

.disclaimer-close:hover {
    color: #000; /* Close button hover color */
}

.disclaimer-text {
    font-size: 1em; /* Text size */
    line-height: 1.6; /* Line height for readability */
    margin: 0; /* No margin for paragraphs */
}

/* Media queries for different screen sizes */
@media (max-width: 1080px) {
    .disclaimer-container {
        padding: 1em;
        width: 45em;
    }

    .disclaimer-text {
        font-size: 0.875em;
    }
}

@media (max-width: 1170px) {
    .disclaimer-container {
        padding: 1em;
        width: 31em;
    }

    .disclaimer-text {
        font-size: 0.875em;
    }
}

@media (max-width: 769px) {
    .disclaimer-container {
        padding: 0.9375em; /* Slightly smaller padding */
        width: 31em; /* Adjusted max width */
    }

    .disclaimer-text {
        font-size: 0.875em; /* Adjusted text size */
    }
}

@media (max-width: 480px) {
    .disclaimer-container {
        padding: 0.625em; /* Smaller padding for small screens */
        width: 20em; /* Adjusted max width */
    }

    .disclaimer-text {
        font-size: 0.75em; /* Smaller text size for small screens */
    }

    .disclaimer-close {
        font-size: 1.125em; /* Slightly smaller close button */
    }
}
