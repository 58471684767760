@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

body{
  display: flex;
  color: aliceblue;
  min-height: 100vh;
  background: var(--bgc);
  font-family: "Poppins", sans-serif;
  margin-top: 2.5rem; /* Añadir margen superior */
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
}

.html {
  overflow-x: hidden;
}
code {
  font-family: "Poppins", sans-serif;
}
