.profiles-container {
  width: 60em;
  margin: 10px auto;
  padding: 20px;
  border-radius: 10px;
  text-align: center; /* Centra todo el contenido dentro del contenedor */
  overflow: hidden;
  font-family: 'Poppins', sans-serif; /* Font family */
  justify-content: center;
  }
  
    /* Media queries para dispositivos más pequeños */
    @media (max-width: 1170px) {
      .profiles-container { width: 45em; }
    }
    
    @media (max-width: 768px) {
      .profiles-container { width: 31em; }
    }
    
    @media (max-width: 1080px) {
      .profiles-container { width: 31em; }
    }
    
    @media (max-width: 480px) {
      .profiles-container {
        width: 20em; /* Ajuste de ancho */
        margin: 0.625em auto; /* Ajustar margen a 10px = 0.625em */
        padding: 1.25em; /* Ajustar padding a 20px = 1.25em */
        border-radius: 0.625em; /* Ajustar border-radius a 10px = 0.625em */
      }
    
      .counter-display {
        font-size: 3em; /* Ajustar font-size */
      }
    
      .button-container {
        display: flex;
        flex-direction: column; /* Cambiar la dirección del contenedor a columna */
        align-items: center; /* Centrar los botones verticalmente */
      }
    
      .tap-button {
        width: 275px; /* Ajustar ancho del botón */
        height: 275px; /* Ajustar alto del botón */
        display: flex; /* Usar flexbox para centrar el contenido */
        justify-content: center; /* Centrar horizontalmente el contenido */
        align-items: center; /* Centrar verticalmente el contenido */
        padding: 0; /* Eliminar padding interno */
        border-radius: 50%; /* Mantener el botón redondo */
        margin: 0.25em; /* Ajustar margen */
        outline: none;
      }
    
      .tap-button img {
        width: 100px; /* Ajustar ancho de la imagen */
        height: 230px; /* Mantener proporción de la imagen */
      }
    
      .approve-container {
        width: 4em; /* Ajustar ancho del contenedor */
      }

      .swal-wide2 {
        width: 23em !important;
        text-align: center;
        justify-content: center;
        margin: auto;
      }
    }
    
    
  .counter-display {
    font-size: 4em;
    text-align: center;
    margin-bottom: 0.3em;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
  }
  
  .tap-button {
    background-color: lightgoldenrodyellow;
    color: white;
    font-size: 1.5em;
    padding: 0.5em 1em;
    margin: 0.5em;
    border: none;
    border-radius: 50%; /* Hace que el botón sea redondo */
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 1px solid coral;
    -webkit-tap-highlight-color: transparent;
  }
  
  .tap-button:hover {
    background-color: moccasin;
  }
  
  /* Efecto de +5 al presionar el botón */
.tap-button:active::after {
  content: "+5";
  position: absolute;
  color: coral;
  font-size: 2rem;
  animation: fadeOut 1s ease-in-out;
  top: -2rem;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-2rem);
  }
}
  .button-container img {
    width: 13em;
  }
  
  .approve-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0.2em, auto;
    width: 5em;
  }
  
  @media (max-width: 600px) {
    .counter-display {
      font-size: 3em;
    }
  }

  .tap-button2 {
  color: #333; /* Letras blancas */
  background-color: #fefefe; /* Cambiado a dorado */
  font-weight: bold;
  border: none;
  padding: 0.5em 1em;
  border-radius: 7px;
  font-size: 1.2em;
  margin: 0.5em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-family: 'Poppins', sans-serif; /* Font family */
}

.tap-button2:hover {
    background-color: coral; /* Cambiado a dorado */
    color: aliceblue; /* Letras blancas */
    transition: all 0.3s ease-in-out;
    animation-name: pulse;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.tap-button2:focus {
  outline: none;
}


@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}