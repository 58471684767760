.carousel {
    position: relative;
    max-width: 70.5em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 2em auto; /* Cambiar ',' por ' ' */
}

.carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 63em; /* Añadir 'em' a la unidad */
    text-align: center;
    padding: 1em;
}

.carousel-controls {
    display: flex;
    justify-content: space-between;
    width: 60%;
    max-width: 65em;
    position: absolute;
    bottom: 7em; /* Cambiar '50px' por '3em' */
}

.carousel-controls > * {
    cursor: pointer;
    font-size: 1.5em;
}

/* Carousel.css */
.carousel-dots {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0.625em; /* Cambiar '10px' por '0.625em' */
}

.carousel-dot {
    width: 0.625em; /* Cambiar '10px' por '0.625em' */
    height: 0.625em; /* Cambiar '10px' por '0.625em' */
    margin: 0 0.3125em; /* Cambiar '5px' por '0.3125em' */
    background-color: #ccc;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.carousel-dot.active {
    background-color: #333;
}

/* Responsive Design */
@media (max-width: 1120px) {
    .carousel, .carousel-controls {
        max-width: 36em; 
    }
}

@media (max-width: 1080px) {
    .carousel, .carousel-controls {
        max-width: 31em;
    }
}

@media (max-width: 720px) {
    .carousel, .carousel-controls {
        max-width: 31em;
    }
}

@media (max-width: 600px) {
    .carousel, .carousel-controls {
        max-width: 27em;
    }
}

@media (max-width: 520px) {
    .carousel, .carousel-controls {
        max-width: 25em;
    }
}

@media (max-width: 480px) {
    .carousel {
        max-width: 20em; /* Ajuste específico para dispositivos de 480px */
    }
    .carousel-controls {
        display: none;
    }
}
