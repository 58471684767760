.vrgdevurret {
    width: 60em;
    margin: 10px auto;
    padding: 20px;
    border-radius: 10px;
    text-align: center; /* Centra todo el contenido dentro del contenedor */
    overflow: hidden;
    font-family: 'Poppins', sans-serif; /* Font family */
  }
  
   /* Media queries para dispositivos más pequeños */
 @media (max-width: 1170px) {
    .vrgdevurret { width: 45em; }
  }
  
  @media (max-width: 768px) {
    .vrgdevurret { width: 31em; }
  }
  
  @media (max-width: 1080px) {
    .vrgdevurret { width: 31em; }
  }
  
  @media (max-width: 480px) {
    .vrgdevurret { width: 20em; }
    .hide-on-mobile {
      display: none;
    }
  }

  .timeline-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 1em auto;
    overflow: hidden;
  }

  .perroverguero04 {
    margin: 0.5em auto;
  }