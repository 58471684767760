.back-link {
    position: absolute;
    top: 7em;
    left: 8m;
    margin: 1em;
    text-decoration: none;
  }
  
  .back-icon {
    font-size: 1.2em; /* Ajusta el tamaño del icono según sea necesario */
    color: aliceblue; /* Hace que el icono tome el color del enlace */
  }

  @media (max-width: 480px) {
    .back-link {
 display: none;
    }
  }
  
  @media screen and (min-width: 520px) and (max-width: 720px) {
    .back-link {
        display: none;
           }
  }
  
  @media screen and (min-width: 721px) and (max-width: 1120px) {
    .comerandreinvest {
      width: 31em; /* Ancho del 80% para pantallas entre 601px y 1120px */
    }
  }
  
  @media screen and (min-width: 1121px) and (max-width: 1080px) {
    .comerandreinvest {
      width: 36em; /* Ancho del 70% para pantallas entre 1121px y 1080px */
    }
  }
  
  @media screen and (min-width: 1081px) {
    .comerandreinvest {
      width: 31em; /* Ancho del 60% para pantallas mayores a 1080px */
    }
  }
  
  