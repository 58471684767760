.box-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  background-size: cover;
  border-radius: 0.625em;
  overflow: hidden;
  margin: 1em;
}

/* Estilos generales */
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: transparent;
}

.home img {
  width: 18em;
  height: auto;
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;
}

.home img:hover {
  transform: scale(1.1);
}

.conteinercontrato {
  background: transparent;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.conteinercontrato:hover {
  transform: translateY(-10px);
}

.classparaspan {
  margin-bottom: 20px;
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  text-align: center;
  display: inline-block; /* Necesario para la animación de escritura */
  white-space: nowrap; /* Evita que el texto se envuelva */
  border-right: 4px solid; /* Añade el borde derecho para el efecto de cursor */
  overflow: hidden; /* Oculta el texto que no cabe */
  animation: typing 6s steps(30, end), blink 3s infinite step-end alternate; /* Aplica las animaciones */
}
.typing-animation {
  animation: typing 6s steps(30, end) infinite;
}

.blink-animation {
  animation: blink 0.75s step-end infinite;
}


@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.button-container {
  display: flex;
  justify-content: center;
}

.Coppy button {
  background: coral;
  color: aliceblue;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.Coppy button:hover {
  background: sandybrown;
  transform: scale(1.05);
}

.Coppy button:active {
  transform: scale(0.95);
}

/* Slider */
.perraputainfameputa {
  overflow: hidden;
  width: 100%;
}

.perraputainfameputa-track {
  display: flex;
  width: 100%;
  animation: marquee 20s linear infinite;
}

.slide-containerputa {
  display: flex;
}

.slideputita2 {
  margin-right: 20px;
}

.image-wrapper {
  width: 6em; /* Ajusta este valor según el tamaño deseado para las imágenes */
  height: 4em; /* Ajusta este valor según el tamaño deseado para las imágenes */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 1px solid #ddd; /* Añade un borde para que se vean uniformes */
  border-radius: 0.5em; /* Bordes redondeados */
  margin: 0.3em; /* Añade el margen alrededor del div */
  background: coral;
  margin-left: 0.3em;
  margin-right: 0.3em;
}

.image-wrapper img {
  max-width: 100%;
  max-height: 100%;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

/*minerlink*/
.ulminer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 0.6em;
  margin-bottom: 0.5em;
}

.ulminer li {
  position: relative;
  list-style-type: none;
}

.ulminer li a {
color: #f0f8ff;
font-size: 3em;
text-decoration: none;
font-family: "Poppins", sans-serif;
}

.blue {
  --clr: gold;
}

.ulminer li a::before {
  content: attr(data-text);
  position: absolute;
  color: var(--clr);
  width: 0;
  overflow: hidden;
  border-right: 8px solid var(--clr);
  -webkit-text-stroke: 1px var(--clr);
  transition: 0.75s ease-in-out;
}

.ulminer li a:hover::before {
  width: 100%;
  filter: drop-shadow(0 0 20px var(--clr));
}